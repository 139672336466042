var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-table", {
          ref: "table",
          attrs: {
            title: "평가 이력",
            tableId: "table",
            editable: _vm.editable && !_vm.disabled,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            columnSetting: false,
            usePaging: false,
            isFullScreen: false,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }